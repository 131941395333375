









































































































































@import '~@/assets/styles/helpers/_variables.scss';

.addAccountButton {
  width: inherit;
  min-height: 210px;
  color: $colorAccent;
  border: 2px dashed $colorAccent;
  position: relative;

  &-actionButton {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &-positiveBalance {
    color: green;
    border: 2px dashed green;
  }

  &-negativeBalance {
    color: red;
    border: 2px dashed red;
  }

  &-childrens { color: $colorAccent; }

  &:hover {
    color: $colorPrimary !important;
    border-color: $colorPrimary !important;

    .addAccountButton-childrens { color: $colorPrimary !important; }
  }
}
